<template>
  <div v-if="states">
    <v-autocomplete
      ref="inputField"
      v-bind="$attrs"
      outlined
      :items="states"
      :item-value="code ? 'code' : 'name'"
      v-model="model"
      :rules="[rules.required]"
      :filter="filter"
      :label="$translation.t('State')"
      auto-select-first
      hide-details="auto"
    >
      <template v-slot:selection="data">
        <div class="d-flex align-items-center">
          {{ data.item.name }}
        </div>
      </template>
      <template v-slot:item="data">
        <div class="d-flex align-items-center">
          {{ data.item.name }}
        </div>
      </template>
    </v-autocomplete>
  </div>
  <v-text-field
    ref="inputField"
    v-else-if="!hideIfNoSelection"
    v-bind="$attrs"
    :label="$translation.t('State')"
    name="state"
    outlined
    v-model="model"
    hide-details="auto"
  />
</template>

<script>
import rules from "@/utils/inputRules";
import CountrySelection from "@/data/countries.json";
import { escapeRegExp } from "lodash";
import projectInputFields from "@/utils/mixins/projectInputFields";

export default {
  name: "StateSelectField",
  props: {
    value: String,
    code: Boolean,
    hideIfNoSelection: Boolean,
    country: {
      type: Boolean,
      required: true,
    },
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    rules,
    CountrySelection,
    model: "",
  }),
  created() {
    this.model = this.state ?? "";
  },
  computed: {
    states() {
      const selectedCountry = CountrySelection.find(
        (country) =>
          country.name === this.country || country.code === this.country
      );

      if (selectedCountry?.states && selectedCountry?.states.length > 0) {
        return selectedCountry.states;
      }

      return false;
    },
    state() {
      return this.projectInformationValue("state");
    },
  },
  methods: {
    filter(item, queryText) {
      const q = new RegExp(escapeRegExp(queryText.toLowerCase()));
      return (
        item?.name?.toLowerCase().match(q) || item?.flag?.toLowerCase().match(q)
      );
    },
  },
  watch: {
    model(newState) {
      this.$emit("input", newState);
    },
    value(newState) {
      this.model = newState;
    },
    state(newState) {
      this.model = newState;
    },
  },
};
</script>

<style scoped></style>
